/* Global styles */
html {
  font-size: 16px;
  height: 100%;
}

body {
  font-family: 'Mulish', sans-serif;
  font-weight: 300;
  margin: 0;
  padding: 0;
  background-color: #ffffff;
  height: 100%;
}

h1, h2 {
  letter-spacing: 11px;
}

p {
  letter-spacing: normal;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.landing-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  text-align: center;
}

.enter-button {
  display: inline-block;
  background-color: #000000;
  color: #ffffff;
  text-decoration: none;
  padding: 12px 24px;
  font-size: 18px;
  border-radius: 4px;
  margin-top: 20px;
  transition: background-color 0.2s;
}

.enter-button:hover {
  background-color: #333333;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
}

footer {
  background-color: #000000;
  color: #ffffff;
  padding: 20px;
  text-align: center;
}

main {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  padding: 20px;
  flex-direction: column;
  align-items: center;
}

.gallery, .generator {
  flex: 1;
  max-width: 600px;
  padding: 20px;
  box-sizing: border-box;
  background-color: #272727;
  border-radius: 4px;
}

h2 {
  margin-top: 0;
  margin-bottom: 20px;
}

nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

nav li {
  margin: 0 1rem;
}

nav a {
  text-decoration: none;
  color: #333;
}

nav a:hover {
  color: #555;
}

body[data-theme="light"] {
  background-color: #696969;
  color: #333333;
}

body[data-theme="dark"] {
  background-color: #000000;
  color: #fdfdfd;
}

body[data-theme="dark"] nav a {
  color: #ffffff;
}

body[data-theme="dark"] nav a:hover {
  color: #bbbbbb;
}

#info-text {
  margin-bottom: 20px;
  text-align: center;
}
#info-text-below {
  margin-bottom: 0px;
  text-align: center;
}

#gallery {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  width: 100%;
}

#scroll-left,
#scroll-right {
  background-color: #000000;
  border: none;
  font-size: 24px;
  padding: 8px 16px;
  cursor: pointer;
}

#gallery-container {
  display: flex;
  overflow-x: scroll;
  scroll-behavior: smooth;
  padding: 0 16px;
  width: 2000px;
  margin: 0 auto;
}

#gallery-container img {
    width: 300px;
    height: 300px;
    object-fit: cover;
    margin-right: 8px;
    border-radius: 4px;
  }
  
  #p5-container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  
  #p5-container canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    transform-origin: top left;
  } 
  
  #regenerate {
    position: relative;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    padding: 8px 16px;
    font-size: 16px;
    background-color: #12b587;
    color: #000000;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;
  }
  
  #regenerate:hover {
    background-color: #000000;
    color: #ffffff;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
  }
  #gallery-container::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: transparent;
  }
  
  #gallery-container::-webkit-scrollbar:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
  
  #gallery-container::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 4px;
  }
  
  #gallery-container::-webkit-scrollbar-thumb:hover {
    background-color: rgba(74, 74, 74, 0.4);
  }
  
  .content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.generator {
    margin-bottom: 20px;
  }
  
  #theme-toggle {
    background-color: #ffffff;
    color: #000000;
    border: none;
    border-radius: 25px;
    padding: 8px 16px;
    font-size: 16px;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;
  }
  
  #theme-toggle:hover {
    background-color: #000000;
    color: #ffffff;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
  }
  .typewriter {
    overflow: hidden;
    border-right: 0.15em solid;
    white-space: nowrap;
    animation: typing 3s steps(30, end), blink-caret 3.75s step-end forwards;
  }
  
  @keyframes typing {
    from {
      width: 0;
    }
    to {
      width: 100%;
    }
  }
  
  @keyframes blink-caret {
    0%, 80% {
      border-color: inherit;
    }
    50%, 100% {
      border-color: transparent;
    }
  }
  .app {
    text-align: center;
    margin-top: 50px;
  }
  
  .app h1 {
    margin-bottom: 30px;
  }
  
  .app button {
    margin: 20px;
  }

#connected-wallet {
  font-size: 14px;
  color: #ffffff;
  opacity: 0.8;
  text-align: right;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

.hidden {
  display: none;
}

#connect-wallet {
  margin-bottom: 20px;
  background-color: #ffffff;
  color: #000000;
  border: none;
  border-radius: 25px;
  padding: 8px 16px;
  font-size: 16px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;
}

#connect-wallet:hover {
  background-color: #0d9464;
}

/* Mint NFT Button */
#mint-nft {
  margin-bottom: 20px;
  background-color: #ffffff;
  color: #000000;
  border: none;
  border-radius: 25px;
  padding: 8px 16px;
  font-size: 16px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;
}

#mint-nft:hover {
  background-color: #0d9464;
}

/* Mint Amount Input */
/* Updated Mint Amount Input */
#mint-amount {
  margin-bottom: 20px;
  border-radius: 4px;
  width: 60px;
  text-align: center;
  background-color: transparent;
  border: 1px solid #ffffff;
  color: #ffffff;
  font-size: 24px;
  padding: 3px;
  transition: border-color 0.2s, color 0.2s;
}

#mint-amount:focus {
  border-color: #12b587;
  color: #12b587;
}


/* Wallet Modal Buttons */
#wallet-modal button {
  background-color: #ffffff;
  color: #000000;
  border: none;
  border-radius: 25px;
  padding: 8px 16px;
  font-size: 16px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;
}

#wallet-modal button:hover {
  background-color: #0d9464;
}

#generate-proof {
  background-color: #ffffff;
  color: #000000;
  border: none;
  border-radius: 25px;
  padding: 8px 16px;
  font-size: 16px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;
}
#generate-proof:hover {
  background-color: #0d9464;
}
#wallet-input {
  margin-top: 20px;
  border-radius: 4px;
  width: 500px;
  text-align: center;
  background-color: transparent;
  border: 1px solid #ffffff;
  color: #ffffff;
  font-size: 24px;
  padding: 3px;
  transition: border-color 0.2s, color 0.2s;
}
#app {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
#connected-wallet {
  align-items: right;
}
#header-logo {
  height: 80px; /* Adjust the height to fit your design */
  align-items: center;
  width: auto; /* Keep the aspect ratio */
}
main {
  position: relative;
}

#background-canvas {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}

.content-wrapper {
  position: relative;
  z-index: 1;
}

.p5-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  overflow: hidden;
}


.centered-paragraph {
  max-width: 1500px; /* Adjust the value as needed */
  margin: 0 auto;
  text-align: center;
  line-height: 3; /* Adjust the value as needed */
  background-image: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.8) 40%, rgba(0, 0, 0, 0.5) 60%, rgba(0, 0, 0, 0.1) 80% ,rgba(0, 0, 0, 0) 100%);
  color: white;
  padding: 15px;
  border-radius: 5px;
}
.social-media-links {
  display: relative;
  gap: 10px;
}

.social-media-links img {
  width: 32px;
  height: 32px;
}
.move-left {
  position: flex;
  right: 150px; /* Adjust this value according to your preference */
}
.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000000; /* Change this color according to your preference */
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-animation {
  width: 150px; /* Adjust the width as needed */
  height: 150px; /* Adjust the height as needed */
}
.other-paragraph {
  max-width: 1500px; /* Adjust the value as needed */
  margin: 0 auto;
  text-align: center;
  line-height: 1; /* Adjust the value as needed */
  background-image: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.8) 40%, rgba(0, 0, 0, 0.5) 60%, rgba(0, 0, 0, 0.1) 80% ,rgba(0, 0, 0, 0) 100%);
  color: white;
  padding: 15px;
  border-radius: 5px;
}
/* Your existing CSS rules */

/* Add the new CSS rules here */
html, body {
  height: 100%;
  margin: 0;
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 100%;
  min-height: 100%;
  padding: 20px;
  box-sizing: border-box;
}
.info-text-wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}
.faq-item {
  max-width: 800px; /* Set the max-width value to the desired width */
  margin: 0 auto; /* Center the faq-item within the wrapper */
  text-align: justify;
}
.team-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
  padding: 0 20px;
}

.team-member {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  width: 300px;
  background-color: #171717;
  animation: fadeInUp 3s ease-out;
}

.team-member img {
  width: 300px;
  height: 300px;
  border-radius: 5px;
  object-fit: cover;
  margin-bottom: 5px;
}

.team-member h2 {
  margin: 0 0 10px;
}

.team-member p {
  color: #a5a5a5;
}
.no-letter-spacing {
  letter-spacing: 0;
}


@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.modal {
  display: none; 
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%; 
  height: 100%; 
  overflow: auto; 
  background-color: rgba(0,0,0,0.4);
}

.modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #000000;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.close {
  color: #aaa;
  font-size: 28px;
  font-weight: bold;
  align-self: flex-end;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.modern-button {
  background-color: #ffffff;
  color: #000000;
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  transition-duration: 0.4s;
  border: 1px solid #000000;
  border-radius: 5px;
}

.modern-button:hover {
  background-color: #000000;
  color: white;
}

.modern-input {
  width: 100%;
  padding: 10px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  border: 1px solid #000000;
  border-radius: 5px;
  transition-duration: 0.4s;
}

.modern-input:focus {
  border: 1px solid #000000;
  outline: none;
  box-shadow: 0 0 10px #000000;
}
#walletAddress {
  margin-top: 20px;
  border-radius: 4px;
  width: 500px;
  text-align: center;
  background-color: transparent;
  border: 1px solid #ffffff;
  color: #ffffff;
  font-size: 24px;
  padding: 3px;
  transition: border-color 0.2s, color 0.2s;
}

@keyframes blink {
  0%, 100% { opacity: 1; }
  50% { opacity: 0.5; }
}

.blinking-border {
  position: relative;
  padding: 20px;
}

.blinking-border::before,
.blinking-border::after {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  border: 2px solid white;
  animation: blink 1s linear infinite;
}

.blinking-border::before {
  top: 0;
  left: 0;
  border-bottom: none;
  border-right: none;
}

.blinking-border::after {
  bottom: 0;
  right: 0;
  border-top: none;
  border-left: none;
}
/* Nav menu underline */

.preview-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  width: 100%;
  max-width: 1500px;  /* 3 * 500px for the preview boxes + some extra for the gaps */
  justify-content: center;
  padding: 20px;
  animation: fadein 2s ease-out forwards;
}

.preview-box {
  width: 100%;
  max-width: 500px;
  height: 500px;
}

@keyframes fadein {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
/* Works on Chrome/Edge/Safari (Scroll bar Delete) */
::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background: transparent; 
}

::-webkit-scrollbar-thumb {
  background: #888; 
  border-radius: 20px; 
}

::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

body::-webkit-scrollbar {
    display: none;
}
/* Corner CSS purple */
.cut-corner-top {
  position: relative;
  padding: 20px;
  background-color: transparent;
  animation: fadein 2s ease-out forwards;
}

.cut-corner-top::before, .cut-corner-top::after {
  content: "";
  position: absolute;
  border: 2px solid rgb(182, 96, 190);
  width: 20px; /* Size of corner line */
  height: 20px; /* Size of corner line */
  animation: blinker 1s linear infinite;
  animation-delay: 2s;
}

.cut-corner-top::before {
  top: 0;
  left: 0;
  border-bottom: none;
  border-right: none;
}

.cut-corner-top::after {
  top: 0;
  right: 0;
  border-bottom: none;
  border-left: none;
}

.cut-corner-bottom {
  position: relative;
  padding: 20px;
  background-color: transparent;
  animation: fadein 2s ease-out forwards;
}

.cut-corner-bottom::before, .cut-corner-bottom::after {
  content: "";
  position: absolute;
  border: 2px solid rgb(182, 96, 190);
  width: 20px; /* Size of corner line */
  height: 20px; /* Size of corner line */
  animation: blinker 1s linear infinite;
  animation-delay: 2s;
}

.cut-corner-bottom::before {
  bottom: 0;
  left: 0;
  border-top: none;
  border-right: none;
}

.cut-corner-bottom::after {
  bottom: 0;
  right: 0;
  border-top: none;
  border-left: none;
}

@keyframes fadein {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes blinker {
  0% { opacity: 1; }
  50% { opacity: 0.5; }
  100% { opacity: 1; }
}
.preview-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  width: 100%;
  max-width: 1500px;  /* 3 * 500px for the preview boxes + some extra for the gaps */
  justify-content: center;
  padding: 20px;
  animation: fadein 2s ease-out forwards;
}

.preview-box {
  width: 100%;
  max-width: 500px;
  height: 500px;
}

@keyframes fadein {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

/* Responsive */
@media (max-width: 768px) {
  .container {
    padding: 0 10px;
  }
  
  nav li {
    margin: 0 0.5rem;
  }
  
  .gallery, .generator {
    max-width: 100%;
    padding: 20px 10px;
  }

  #wallet-input {
    width: 100%;
    max-width: 300px;
  }

  #mint-amount {
    font-size: 20px;
  }

  h1, h2 {
    font-size: 24px;
    letter-spacing: 6px;
  }

  .enter-button {
    font-size: 16px;
    padding: 10px 20px;
  }

  #scroll-left,
  #scroll-right {
    font-size: 20px;
    padding: 6px 12px;
  }

  #regenerate {
    font-size: 14px;
    padding: 6px 12px;
  }

  #theme-toggle, #connect-wallet, #mint-nft, #generate-proof, #wallet-modal button {
    font-size: 14px;
    padding: 6px 12px;
  }

  .centered-paragraph, .other-paragraph {
    font-size: 16px;
    text-align: center;
  }

  .social-media-links img {
    width: 24px;
    height: 24px;
  }

  #p5-container {
    width: 100%;
    padding-bottom: 100%;
  }
  .wrapper {
    padding: 8px;
  }
  .move-left {
    position: flex;
    right: 150px; /* Adjust this value according to your preference */
  }
  #canvas-container {
    width: 80%;
    height: auto;
  }
  #p5-container {
    width: 100%;
    height: 100%;
  }

  #p5-container canvas {
    width: 75vw;
    height: 75vw;
    object-fit: contain;
  }
  .other-paragraph {
    padding: -15px;
  }
  .team-container {
    gap: 10px;
    padding: 0 10px;
  }

  .team-member, .team-member img {
    width: 100%;
    max-width: 300px;
  }
  .preview-container {
    grid-template-columns: 1fr;
    height: auto;
    width: 300;
  }

  .preview-box {
    height: auto;
    width: 300px;
  }
}



@media (min-width: 1000px) {
  #p5-container {
    position: relative;
    align-items: center;
    width: 800px;
    height: 800px;
  }
    
    .p5-container {
      width: 100%;
      height: 100%;
    }

}
@media (min-width: 769px) {
  .nav-item a {
    position: relative;
  }

  .nav-item a::after {
    content: "";
    display: block;
    position: absolute;
    bottom: 20;
    left: 0;
    width: 100%;
    height: 2px;
    background: #ffffff; /* Change to the color you want */
    transform: scaleX(0);
    transition: all 0.5s ease-in-out;
  }

  .nav-item a:hover::after {
    transform: scaleX(1);
  }
}











  
   
  

  
  
  
  
  
  