html {
  height: 100%;
  font-size: 16px;
}

body {
  height: 100%;
  background-color: #fff;
  margin: 0;
  padding: 0;
  font-family: Mulish, sans-serif;
  font-weight: 300;
}

h1, h2 {
  letter-spacing: 11px;
}

p {
  letter-spacing: normal;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.landing-page {
  min-height: 100vh;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.enter-button {
  color: #fff;
  background-color: #000;
  border-radius: 4px;
  margin-top: 20px;
  padding: 12px 24px;
  font-size: 18px;
  text-decoration: none;
  transition: background-color .2s;
  display: inline-block;
}

.enter-button:hover {
  background-color: #333;
}

header {
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  display: flex;
}

footer {
  color: #fff;
  text-align: center;
  background-color: #000;
  padding: 20px;
}

main {
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 20px;
  display: flex;
}

.gallery, .generator {
  max-width: 600px;
  box-sizing: border-box;
  background-color: #272727;
  border-radius: 4px;
  flex: 1;
  padding: 20px;
}

h2 {
  margin-top: 0;
  margin-bottom: 20px;
}

nav ul {
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
}

nav li {
  margin: 0 1rem;
}

nav a {
  color: #333;
  text-decoration: none;
}

nav a:hover {
  color: #555;
}

body[data-theme="light"] {
  color: #333;
  background-color: #696969;
}

body[data-theme="dark"] {
  color: #fdfdfd;
  background-color: #000;
}

body[data-theme="dark"] nav a {
  color: #fff;
}

body[data-theme="dark"] nav a:hover {
  color: #bbb;
}

#info-text {
  text-align: center;
  margin-bottom: 20px;
}

#info-text-below {
  text-align: center;
  margin-bottom: 0;
}

#gallery {
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  display: flex;
}

#scroll-left, #scroll-right {
  cursor: pointer;
  background-color: #000;
  border: none;
  padding: 8px 16px;
  font-size: 24px;
}

#gallery-container {
  scroll-behavior: smooth;
  width: 2000px;
  margin: 0 auto;
  padding: 0 16px;
  display: flex;
  overflow-x: scroll;
}

#gallery-container img {
  width: 300px;
  height: 300px;
  object-fit: cover;
  border-radius: 4px;
  margin-right: 8px;
}

#p5-container {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  overflow: hidden;
}

#p5-container canvas {
  width: 100%;
  height: 100%;
  object-fit: contain;
  transform-origin: 0 0;
  position: absolute;
  top: 0;
  left: 0;
}

#regenerate {
  color: #000;
  cursor: pointer;
  background-color: #12b587;
  border: none;
  border-radius: 25px;
  padding: 8px 16px;
  font-size: 16px;
  transition: background-color .3s, color .3s, box-shadow .3s;
  position: relative;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0 4px 6px #0000001a;
}

#regenerate:hover {
  color: #fff;
  background-color: #000;
  box-shadow: 0 6px 8px #0003;
}

#gallery-container::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #0000;
}

#gallery-container::-webkit-scrollbar:hover {
  background-color: #0000001a;
}

#gallery-container::-webkit-scrollbar-thumb {
  background-color: #0003;
  border-radius: 4px;
}

#gallery-container::-webkit-scrollbar-thumb:hover {
  background-color: #4a4a4a66;
}

.content-container {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.generator {
  margin-bottom: 20px;
}

#theme-toggle {
  color: #000;
  cursor: pointer;
  background-color: #fff;
  border: none;
  border-radius: 25px;
  padding: 8px 16px;
  font-size: 16px;
  transition: background-color .3s, color .3s, box-shadow .3s;
  box-shadow: 0 4px 6px #0000001a;
}

#theme-toggle:hover {
  color: #fff;
  background-color: #000;
  box-shadow: 0 6px 8px #0003;
}

.typewriter {
  white-space: nowrap;
  border-right: .15em solid;
  animation: 3s steps(30, end) typing, 3.75s step-end forwards blink-caret;
  overflow: hidden;
}

@keyframes typing {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

@keyframes blink-caret {
  0%, 80% {
    border-color: inherit;
  }

  50%, 100% {
    border-color: #0000;
  }
}

.app {
  text-align: center;
  margin-top: 50px;
}

.app h1 {
  margin-bottom: 30px;
}

.app button {
  margin: 20px;
}

#connected-wallet {
  color: #fff;
  opacity: .8;
  text-align: right;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 200px;
  font-size: 14px;
  overflow: hidden;
}

.hidden {
  display: none;
}

#connect-wallet {
  color: #000;
  cursor: pointer;
  background-color: #fff;
  border: none;
  border-radius: 25px;
  margin-bottom: 20px;
  padding: 8px 16px;
  font-size: 16px;
  transition: background-color .3s, color .3s, box-shadow .3s;
  box-shadow: 0 4px 6px #0000001a;
}

#connect-wallet:hover {
  background-color: #0d9464;
}

#mint-nft {
  color: #000;
  cursor: pointer;
  background-color: #fff;
  border: none;
  border-radius: 25px;
  margin-bottom: 20px;
  padding: 8px 16px;
  font-size: 16px;
  transition: background-color .3s, color .3s, box-shadow .3s;
  box-shadow: 0 4px 6px #0000001a;
}

#mint-nft:hover {
  background-color: #0d9464;
}

#mint-amount {
  width: 60px;
  text-align: center;
  color: #fff;
  background-color: #0000;
  border: 1px solid #fff;
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 3px;
  font-size: 24px;
  transition: border-color .2s, color .2s;
}

#mint-amount:focus {
  color: #12b587;
  border-color: #12b587;
}

#wallet-modal button {
  color: #000;
  cursor: pointer;
  background-color: #fff;
  border: none;
  border-radius: 25px;
  padding: 8px 16px;
  font-size: 16px;
  transition: background-color .3s, color .3s, box-shadow .3s;
  box-shadow: 0 4px 6px #0000001a;
}

#wallet-modal button:hover {
  background-color: #0d9464;
}

#generate-proof {
  color: #000;
  cursor: pointer;
  background-color: #fff;
  border: none;
  border-radius: 25px;
  padding: 8px 16px;
  font-size: 16px;
  transition: background-color .3s, color .3s, box-shadow .3s;
  box-shadow: 0 4px 6px #0000001a;
}

#generate-proof:hover {
  background-color: #0d9464;
}

#wallet-input {
  width: 500px;
  text-align: center;
  color: #fff;
  background-color: #0000;
  border: 1px solid #fff;
  border-radius: 4px;
  margin-top: 20px;
  padding: 3px;
  font-size: 24px;
  transition: border-color .2s, color .2s;
}

#app {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

#connected-wallet {
  align-items: right;
}

#header-logo {
  height: 80px;
  width: auto;
  align-items: center;
}

main {
  position: relative;
}

#background-canvas {
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.content-wrapper {
  z-index: 1;
  position: relative;
}

.p5-background {
  z-index: -1;
  position: fixed;
  inset: 0;
  overflow: hidden;
}

.centered-paragraph {
  max-width: 1500px;
  text-align: center;
  color: #fff;
  background-image: radial-gradient(#000c 40%, #00000080 60%, #0000001a 80%, #0000 100%);
  border-radius: 5px;
  margin: 0 auto;
  padding: 15px;
  line-height: 3;
}

.social-media-links {
  display: relative;
  gap: 10px;
}

.social-media-links img {
  width: 32px;
  height: 32px;
}

.move-left {
  position: flex;
  right: 150px;
}

.loading-screen {
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: #000;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.loading-animation {
  width: 150px;
  height: 150px;
}

.other-paragraph {
  max-width: 1500px;
  text-align: center;
  color: #fff;
  background-image: radial-gradient(#000c 40%, #00000080 60%, #0000001a 80%, #0000 100%);
  border-radius: 5px;
  margin: 0 auto;
  padding: 15px;
  line-height: 1;
}

html, body {
  height: 100%;
  margin: 0;
}

.wrapper {
  width: 100%;
  max-width: 100%;
  min-height: 100%;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  display: flex;
}

.info-text-wrapper {
  justify-content: center;
  margin-bottom: 2rem;
  display: flex;
}

.faq-item {
  max-width: 800px;
  text-align: justify;
  margin: 0 auto;
}

.team-container {
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
  padding: 0 20px;
  display: flex;
}

.team-member {
  text-align: center;
  width: 300px;
  background-color: #171717;
  border-radius: 5px;
  padding: 20px;
  animation: 3s ease-out fadeInUp;
  box-shadow: 0 0 10px #0000001a;
}

.team-member img {
  width: 300px;
  height: 300px;
  object-fit: cover;
  border-radius: 5px;
  margin-bottom: 5px;
}

.team-member h2 {
  margin: 0 0 10px;
}

.team-member p {
  color: #a5a5a5;
}

.no-letter-spacing {
  letter-spacing: 0;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.modal {
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: #0006;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
}

.modal-content {
  width: 80%;
  background-color: #000;
  border: 1px solid #888;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.close {
  color: #aaa;
  align-self: flex-end;
  font-size: 28px;
  font-weight: bold;
}

.close:hover, .close:focus {
  color: #000;
  cursor: pointer;
  text-decoration: none;
}

.modern-button {
  color: #000;
  text-align: center;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 5px;
  margin: 4px 2px;
  padding: 10px 20px;
  font-size: 16px;
  text-decoration: none;
  transition-duration: .4s;
  display: inline-block;
}

.modern-button:hover {
  color: #fff;
  background-color: #000;
}

.modern-input {
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #000;
  border-radius: 5px;
  margin: 8px 0;
  padding: 10px 20px;
  transition-duration: .4s;
}

.modern-input:focus {
  border: 1px solid #000;
  outline: none;
  box-shadow: 0 0 10px #000;
}

#walletAddress {
  width: 500px;
  text-align: center;
  color: #fff;
  background-color: #0000;
  border: 1px solid #fff;
  border-radius: 4px;
  margin-top: 20px;
  padding: 3px;
  font-size: 24px;
  transition: border-color .2s, color .2s;
}

@keyframes blink {
  0%, 100% {
    opacity: 1;
  }

  50% {
    opacity: .5;
  }
}

.blinking-border {
  padding: 20px;
  position: relative;
}

.blinking-border:before, .blinking-border:after {
  content: "";
  width: 10px;
  height: 10px;
  border: 2px solid #fff;
  animation: 1s linear infinite blink;
  position: absolute;
}

.blinking-border:before {
  border-bottom: none;
  border-right: none;
  top: 0;
  left: 0;
}

.blinking-border:after {
  border-top: none;
  border-left: none;
  bottom: 0;
  right: 0;
}

.preview-container {
  width: 100%;
  max-width: 1500px;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  gap: 10px;
  padding: 20px;
  animation: 2s ease-out forwards fadein;
  display: grid;
}

.preview-box {
  width: 100%;
  max-width: 500px;
  height: 500px;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background: none;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 20px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

body::-webkit-scrollbar {
  display: none;
}

.cut-corner-top {
  background-color: #0000;
  padding: 20px;
  animation: 2s ease-out forwards fadein;
  position: relative;
}

.cut-corner-top:before, .cut-corner-top:after {
  content: "";
  width: 20px;
  height: 20px;
  border: 2px solid #b660be;
  animation: 1s linear 2s infinite blinker;
  position: absolute;
}

.cut-corner-top:before {
  border-bottom: none;
  border-right: none;
  top: 0;
  left: 0;
}

.cut-corner-top:after {
  border-bottom: none;
  border-left: none;
  top: 0;
  right: 0;
}

.cut-corner-bottom {
  background-color: #0000;
  padding: 20px;
  animation: 2s ease-out forwards fadein;
  position: relative;
}

.cut-corner-bottom:before, .cut-corner-bottom:after {
  content: "";
  width: 20px;
  height: 20px;
  border: 2px solid #b660be;
  animation: 1s linear 2s infinite blinker;
  position: absolute;
}

.cut-corner-bottom:before {
  border-top: none;
  border-right: none;
  bottom: 0;
  left: 0;
}

.cut-corner-bottom:after {
  border-top: none;
  border-left: none;
  bottom: 0;
  right: 0;
}

@keyframes blinker {
  0% {
    opacity: 1;
  }

  50% {
    opacity: .5;
  }

  100% {
    opacity: 1;
  }
}

.preview-container {
  width: 100%;
  max-width: 1500px;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  gap: 10px;
  padding: 20px;
  animation: 2s ease-out forwards fadein;
  display: grid;
}

.preview-box {
  width: 100%;
  max-width: 500px;
  height: 500px;
}

@media (max-width: 768px) {
  .container {
    padding: 0 10px;
  }

  nav li {
    margin: 0 .5rem;
  }

  .gallery, .generator {
    max-width: 100%;
    padding: 20px 10px;
  }

  #wallet-input {
    width: 100%;
    max-width: 300px;
  }

  #mint-amount {
    font-size: 20px;
  }

  h1, h2 {
    letter-spacing: 6px;
    font-size: 24px;
  }

  .enter-button {
    padding: 10px 20px;
    font-size: 16px;
  }

  #scroll-left, #scroll-right {
    padding: 6px 12px;
    font-size: 20px;
  }

  #regenerate, #theme-toggle, #connect-wallet, #mint-nft, #generate-proof, #wallet-modal button {
    padding: 6px 12px;
    font-size: 14px;
  }

  .centered-paragraph, .other-paragraph {
    text-align: center;
    font-size: 16px;
  }

  .social-media-links img {
    width: 24px;
    height: 24px;
  }

  #p5-container {
    width: 100%;
    padding-bottom: 100%;
  }

  .wrapper {
    padding: 8px;
  }

  .move-left {
    position: flex;
    right: 150px;
  }

  #canvas-container {
    width: 80%;
    height: auto;
  }

  #p5-container {
    width: 100%;
    height: 100%;
  }

  #p5-container canvas {
    width: 75vw;
    height: 75vw;
    object-fit: contain;
  }

  .other-paragraph {
    padding: -15px;
  }

  .team-container {
    gap: 10px;
    padding: 0 10px;
  }

  .team-member, .team-member img {
    width: 100%;
    max-width: 300px;
  }

  .preview-container {
    height: auto;
    width: 300px;
    grid-template-columns: 1fr;
  }

  .preview-box {
    height: auto;
    width: 300px;
  }
}

@media (min-width: 1000px) {
  #p5-container {
    width: 800px;
    height: 800px;
    align-items: center;
    position: relative;
  }

  .p5-container {
    width: 100%;
    height: 100%;
  }
}

@media (min-width: 769px) {
  .nav-item a {
    position: relative;
  }

  .nav-item a:after {
    content: "";
    width: 100%;
    height: 2px;
    background: #fff;
    transition: all .5s ease-in-out;
    display: block;
    position: absolute;
    bottom: 20px;
    left: 0;
    transform: scaleX(0);
  }

  .nav-item a:hover:after {
    transform: scaleX(1);
  }
}

/*# sourceMappingURL=index.8191253f.css.map */
